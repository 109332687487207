// https://github.com/ArkoseLabs/arkose-react-example
import React, { useEffect } from "react";

const ArkoseLabs = (props) => {
    const { publicKey, idSelector, callbackFuncName } = props
    window.Arkose = {};

    // We only want to have the API script tag created once
    useEffect(() => {
        return createArkoseScript(publicKey, callbackFuncName);
        // eslint-disable-next-line
    }, []);

    function createArkoseScript(publicKey, callbackFuncName) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
            "https://client-api.arkoselabs.com/v2/" + publicKey + "/api.js";
        script.setAttribute("data-callback", callbackFuncName);
        script.async = true;
        script.defer = true;
        script.id = "arkose-script";

        document.head.append(script);

        return () => {
            const object = document.getElementById("arkose-script");
            object.remove();
        };
    }

    return <div id={idSelector}></div>;
};

export default ArkoseLabs;